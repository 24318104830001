import { ApeironLinkButton, IconText } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  position: relative;
`

const StyledApeironLinkButton = styled(ApeironLinkButton)`
  background-color: black;
  border-radius: 100px;
  border: 2px solid white;
  height: calc(100% - 16px);
  position: absolute;
  width: 100%;
`

const StyledIconText = styled(IconText)`
  gap: 10px;
  pointer-events: none;
  position: relative;
  .Custom-Icon {
    width: 68px;
  }
  .Custom-Text {
    font-size: 16px;
    font-weight: 400;
    max-width: 124px;
    text-align: left;
    white-space: break-spaces;
  }
`

const ApeironGotoRoundButton: FC<Props> = props => {
  const { className, icon, url, target, text } = props

  return (
    <Box className={className}>
      <Container className='Custom-Container'>
        <StyledApeironLinkButton
          className='Custom-Link'
          url={url}
          target={target}
        />
        <StyledIconText
          className='Custom-Icon-Text'
          startSrc={icon}
          text={text}
        />
      </Container>
    </Box>
  )
}

type Props = {
  className?: string
  icon: string
  url: string
  target?: string
  text: string
}

export default ApeironGotoRoundButton
