import { CurrentSeason } from '@src/types/season'

const useMakeSeason = () => {
  const makeCurrentSeason = (input: any): CurrentSeason => {
    const result = {
      breakpoint: {
        desktop: {
          background: input.breakpoint.desktop.background,
          barColor: input.breakpoint.desktop.barColor,
          barSide: input.breakpoint.desktop.barSide,
          barVersion: input.breakpoint.desktop.barVersion,
          logo: input.breakpoint.desktop.logo
        },
        mobile: {
          background: input.breakpoint.mobile.background,
          barColor: input.breakpoint.mobile.barColor,
          barSide: input.breakpoint.mobile.barSide,
          barVersion: input.breakpoint.mobile.barVersion,
          logo: input.breakpoint.mobile.logo
        }
      },
      name: input.name,
      title: input.title
    }

    return result
  }

  return {
    makeCurrentSeason
  }
}

export default useMakeSeason
