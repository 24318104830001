import {
  ApeironLinkButton,
  AssetType,
  NextImage,
  noForwardProps,
  useApeironPathMaker
} from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import BackgroundAdCard from '@public/images/background-battle-demo-ad-card-title.png'
import ShortcutItems from '@src/components/battle/demo/download/Shortcuts'
import BattleDemoTitle from '@src/components/battle/demo/share/BattleDemoTitle'
import ApeironPurpleDivider from '@src/components/share/apeiron/ApeironPurpleDivider'
import { BannerType } from '@src/constants/banner'
import { DEFAULT_ANIMATE_DURATION } from '@src/constants/layout'
import useGetBanners from '@src/hooks/graphql/useGetBanners'
import useFetchBattleDemoAboutJson from '@src/hooks/json/useFetchBattleDemoAboutJson'
import { BattleDemoAboutCard } from '@src/types/battleDemo'
import * as R from 'ramda'
import { FC, useEffect, useMemo } from 'react'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PaginationOptions } from 'swiper/types'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
`

const Title = styled(BattleDemoTitle)`
  ${props => props.theme.breakpoints.up('res1024')} {
    margin: 0px 0px 0px 90px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    margin: 0px 0px 0px 20px;
  }
`

const Divider = styled(ApeironPurpleDivider)`
  align-self: center;
  max-width: 1768px;
  width: 100%;
`

const Banners = styled(Swiper)`
  aspect-ratio: 740 / 476;
  max-width: 740px;
  width: 95%;

  & .swiper-pagination {
    bottom: 1%;
    cursor: pointer;
    display: flex;
    gap: 50px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0px);
    z-index: 1;
  }
  & .swiper-pagination-bullet {
    aspect-ratio: 1;
    background-color: transparent;
    border: 2px solid white;
    transform: rotate(45deg);
    transition: background-color ${DEFAULT_ANIMATE_DURATION}ms,
      transform ${DEFAULT_ANIMATE_DURATION}ms;

    ${props => props.theme.breakpoints.up('res1024')} {
      width: 20px;
    }
    ${props => props.theme.breakpoints.down('res1024')} {
      width: 16px;
    }

    :hover {
      background-color: #ffffff33;
      transform: scale(0.925) rotate(45deg);
    }
  }
  & .swiper-pagination-bullet-active {
    background-color: white;
  }
`

const Banner = styled(ApeironLinkButton)`
  aspect-ratio: 740 / 416;
  max-width: 740px;
  transition: filter ${DEFAULT_ANIMATE_DURATION}ms;
  width: 100%;

  :hover {
    filter: brightness(1.1);
  }
`

const BannerImage = styled(NextImage)`
  height: 100%;
  width: 100%;
`

const CardsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Cards = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 940px;
  max-width: 90%;
`

const CardContainer = styled(Box)`
  background-color: black;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: filter ${DEFAULT_ANIMATE_DURATION}ms,
    transform ${DEFAULT_ANIMATE_DURATION}ms;
  width: 300px;
  :hover {
    filter: brightness(1.2);
    transform: translateY(-5px);
  }
`

const CardImageBox = styled(Box, noForwardProps)<AspectRatioProps>`
  aspect-ratio: ${props => props.__aspectRatio};
  border: 2px solid #584635;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
`

const CardImage = styled(NextImage, noForwardProps)`
  width: 100%;
  height: 100%;
`

const CardTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`

const CardDescription = styled(Typography)`
  font-size: 14px;
  color: #cccccc;
  white-space: pre-line;
`

const AdCardTitleContainer = styled(Box)`
  background: url(${BackgroundAdCard.src}) no-repeat;
  padding: 4px 10px;
  text-align: center;
`

const CardDescriptionContainer = styled(Box)`
  padding: 10px 15px 20px 15px;
  min-height: 200px;
`

const Card: FC<CardProps> = props => {
  const { card, className } = props

  const { image, title, description, imageAspectRatio } = card

  return (
    <CardContainer className={className}>
      <CardImageBox __aspectRatio={imageAspectRatio}>
        <CardImage src={image} />
      </CardImageBox>
      <Box>
        <AdCardTitleContainer>
          <CardTitle>{title}</CardTitle>
        </AdCardTitleContainer>
        <CardDescriptionContainer>
          <CardDescription>{description}</CardDescription>
        </CardDescriptionContainer>
      </Box>
    </CardContainer>
  )
}

const AboutSection: FC<Props> = props => {
  const { className } = props

  const { data: about } = useFetchBattleDemoAboutJson()

  const { data: banners, fetch: fetchBanner } = useGetBanners()

  const { makePath } = useApeironPathMaker()

  const { apeironTitle = '', title = '', content = [] } = about || {}

  const pagination = useMemo((): PaginationOptions => {
    return {
      clickable: true
    }
  }, [])

  useEffect(() => {
    fetchBanner({ bannerTypes: [BannerType.Home] })
  }, [])

  return (
    <Container className={className}>
      <Title apeironTitle={apeironTitle} title={title} />
      <Divider />
      <Banners
        pagination={pagination}
        spaceBetween={'5%'}
        modules={[Pagination]}
      >
        {R.map(banner => {
          const path = makePath(AssetType.Image, banner.bannerImage)

          return (
            <SwiperSlide key={banner.displayOrder}>
              <Banner url={banner.actionValue} target='_blank'>
                <BannerImage src={path} />
              </Banner>
            </SwiperSlide>
          )
        }, banners)}
      </Banners>
      <CardsContainer>
        <Cards>
          {R.map(
            card => (
              <Card key={`${card.title}${card.description}`} card={card} />
            ),
            content
          )}
        </Cards>
      </CardsContainer>
      <ShortcutItems />
    </Container>
  )
}

type AspectRatioProps = {
  __aspectRatio: number
}

type CardProps = {
  card: BattleDemoAboutCard
  className?: string
}

type Props = {
  className?: string
}
export default AboutSection
