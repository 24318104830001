import { Box, styled } from '@mui/material'
import { FC } from 'react'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  gap: 36px;
`

const LeftLine = styled(Box)`
  background: linear-gradient(90deg, #5d388455 0%, #5d3884 100%);
  flex: 1;
  height: 5px;
`

const RightLine = styled(LeftLine)`
  background: linear-gradient(90deg, #5d3884 0%, #5d388455 100%);
  flex: 1;
  height: 5px;
`

const Point = styled(Box)`
  aspect-ratio: 1;
  background-color: white;
  border: 2px solid #893ef7;
  transform: rotate(45deg);
  width: 18px;
`

const ApeironPurpleDivider: FC<Props> = props => {
  const { className } = props

  return (
    <Container className={className}>
      <LeftLine />
      <Point />
      <RightLine />
    </Container>
  )
}

type Props = {
  className?: string
}

export default ApeironPurpleDivider
