export enum GAAssetType {
  Apostle = 'Apostle',
  Godiverse = 'Astronomical', // TODO - should be 'Godiverse', need to support both on GA
  BlackHole = 'BlackHole',
  Emblem = 'Emblem',
  Planet = 'Planet',
  Star = 'Star'
}

export enum GAEventCategory {
  AddToFavorite = 'add_to_favorite',
  Download = 'download',
  Filter = 'filter',
  Purchase = 'purchase',
  SelectItem = 'select_item',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list'
}
