import { BannerActionType, BannerType } from '@src/constants/banner'
import { Banner } from '@src/types/banner'
import * as yup from 'yup'

export const bannerTypeSchema: yup.BaseSchema<BannerType> = yup
  .mixed()
  .oneOf(Object.values(BannerType))
  .default(BannerType.Home)

export const bannerActionTypeSchema: yup.BaseSchema<BannerActionType> = yup
  .mixed()
  .oneOf(Object.values(BannerActionType))
  .default(BannerActionType.Link)

export const bannerSchema: yup.SchemaOf<Banner> = yup
  .object({
    actionType: bannerActionTypeSchema,
    actionValue: yup.string().default(''),
    bannerImage: yup.string().default(''),
    bannerType: bannerTypeSchema,
    displayOrder: yup.number().default(0)
  })
  .noUnknown(true)
