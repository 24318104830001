export enum BannerType {
  Home = 'Home',
  DailyCheckIn = 'DailyCheckIn'
}

export enum BannerActionType {
  Link = 'Link',
  Click = 'Click'
}

export const BANNER_SLIDE_AUTOPLAY_DURATION = 10000
