import {
  CurrencyTokenType,
  DownloadInfo,
  NextImage,
  noForwardProps,
  useAccountContext,
  useCurrencyContext
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import LootRushPNG from '@public/icons/icon-lootrush-square.png'
import StickyBackgroundJPG from '@public/images/background-download-2.png'
import AboutSection from '@src/components/battle/demo/download/AboutSection'
import DownloadSection from '@src/components/battle/demo/download/DownloadSection'
import BattleDemoSecNavBar from '@src/components/navigationBar/secondary/BattleDemoSecNavBar'
import ApeironGotoRoundButton from '@src/components/share/apeiron/ApeironGotoRoundButton'
import ENV_CONFIG from '@src/config'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { ROUTE } from '@src/constants/route'
import { useSecondaryNavBarContext } from '@src/contexts/share/SecondaryNavBarContext'
import useFetchBattleDemoConfigJson from '@src/hooks/json/useFetchBattleDemoConfigJson'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  background-color: #12151c;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
`

const BackgroundContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  position: relative;
  width: 100%;
`

const GotoDailyCheckIn = styled(
  ApeironGotoRoundButton,
  noForwardProps
)<GotoProps>`
  position: fixed;
  ${props => props.theme.breakpoints.up('res1024')} {
    right: 30px;
    top: calc(134px - ${props => (props.__noSubbar ? 56 : 0)}px);
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    right: 12px;
    top: calc(124px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      padding: 6px 10px;
      .Custom-Link {
        background-color: #000000aa;
      }
      .Custom-Icon-Text {
        gap: 4px;
        .Custom-Icon {
          width: 48px;
          margin: 0px 0px 0px 7px;
        }
        .Custom-Text {
          font-size: 14px;
          line-height: 18px;
          max-width: 100px;
        }
      }
    }
  }
`

const GotoLootRush = styled(GotoDailyCheckIn, noForwardProps)<GotoProps>`
  ${props => props.theme.breakpoints.up('res1024')} {
    top: calc(214px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      .Custom-Icon-Text {
        gap: 14px;
        .Custom-Icon {
          width: 50px;
          margin: 10px 0px 10px 8px;
        }
        .Custom-Text {
          font-size: 16px;
          max-width: 130px;
        }
      }
    }
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    top: calc(184px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      .Custom-Icon-Text {
        gap: 10px;
        .Custom-Icon {
          width: 36px;
          margin: 8px 0px 8px 12px;
        }
      }
    }
  }
`

const StickyBackground = styled(Box)`
  position: sticky;
  top: 0px;
  width: 100%;
`

const StickyImage = styled(NextImage)`
  aspect-ratio: 1918/395;
  background: linear-gradient(180deg, #000 0%, #00000000 50%);
  margin-bottom: calc(-100% * 395 / 1918 + 100px);
  width: 100%;
`

const GamePage: NextPage<Props> = props => {
  const { className, homeMode = false } = props

  const { query } = useRouter()

  const { promptLogin } = query

  const { isLoggedIn, setShowLoginPopup } = useAccountContext()

  const { setupBar } = useSecondaryNavBarContext()

  const { getCurrency } = useCurrencyContext()

  const { data: config } = useFetchBattleDemoConfigJson()

  const { t } = useTranslation()

  const currency = getCurrency(CurrencyTokenType.Stardust)

  const [downloadInfoList, setDownloadInfoList] = useState<DownloadInfo[]>([])

  useEffect(() => {
    if (R.isNotNil(config)) {
      setDownloadInfoList(config.downloadMap.battleDemo)
    }
  }, [config])

  useEffect(() => {
    if (promptLogin && !isLoggedIn) {
      setShowLoginPopup(true)
    }
  }, [isLoggedIn, promptLogin, setShowLoginPopup])

  useEffect(() => {
    if (!homeMode) {
      setupBar(<BattleDemoSecNavBar route={ROUTE.BATTLE_DEMO_GAME} />)
    }

    return () => setupBar(null)
  }, [setupBar, homeMode])

  return (
    <Container className={className}>
      <BackgroundContainer>
        <DownloadSection infoList={downloadInfoList} showTitle={!homeMode} />
      </BackgroundContainer>
      <BackgroundContainer>
        <StickyBackground>
          <StickyImage src={StickyBackgroundJPG} />
        </StickyBackground>
        <AboutSection />
      </BackgroundContainer>
      <GotoDailyCheckIn
        __noSubbar={homeMode}
        icon={currency?.image || ''}
        url={ROUTE.REWARD_DAILY_CHECK_IN}
        text={t('battle_demo.start_check_in')}
      />
      {featureAllowed(FeatureFlagType.ShowLootRush) && (
        <GotoLootRush
          __noSubbar={homeMode}
          icon={LootRushPNG.src}
          url={ENV_CONFIG.EXTERNAL_URL.LOOTRUSH.APEIRON}
          target='_blank'
          text={t('battle_demo.go_to_lootrush')}
        />
      )}
    </Container>
  )
}

type GotoProps = {
  __noSubbar: boolean
}

type Props = {
  className?: string
  homeMode?: boolean
}

export default GamePage
