import { gql } from '@apollo/client'
export default gql`
  query GetBanners($input: BannersInput!) {
    getBanners(input: $input) {
      banners {
        actionType
        actionValue
        bannerImage
        bannerType
        displayOrder
      }
    }
  }
`
